import React from "react"
import { PAGE, TEST_ICON_STYLE } from "../../../values"
import { navigate } from "gatsby"
const styles = require("./style.module.scss")

interface HeaderButtonProps {
  style?: React.CSSProperties
  src: string
}

const HeaderButton = ({ style, src }: HeaderButtonProps) => {
  return (
    <div style={style} className={styles["buttonStyle"]}>
      <img src={src} className={styles["buttonImage"]} />
    </div>
  )
}

export default HeaderButton
