import React from "react"
import { PAGE } from "../../../values"
import AddConfirmModal from "../AddConfirmModal"
import FooterBar from "../FooterBar"
import HeaderBar from "../HeaderBar"
import BottomNavigationBar from "./../BottomNavigationBar/index"

const Layout = ({
  children,
  isSearchPage,
  nowPage,
}: {
  children: React.ReactNode
  isSearchPage?: boolean
  nowPage?: PAGE
}) => {
  const isTesting = false

  return (
    <>
      {isTesting ? (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Noto Sans KR",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              fontSize: 18,
            }}
          >
            웹사이트가 현재 보수 중에 있습니다
          </span>
          <span
            style={{
              marginBottom: 25,
            }}
          >
            이용에 불편을 드려 죄송합니다.
          </span>
          <div
            className="v-box"
            style={{
              border: "1px solid rgba(0,0,0,0.1)",
              padding: 20,
              alignItems: "center",
            }}
          >
            <div
              className="h-box"
              style={{
                alignItems: "center",
                marginBottom: 5,
              }}
            >
              <img
                src={require("../../../images/favicon.png")}
                style={{
                  width: 30,
                  height: 30,
                  marginRight: 5,
                }}
              />
              <span
                style={{
                  fontWeight: 700,
                  fontSize: 17,
                }}
              >
                효자콜마트
              </span>
            </div>
            <span
              style={{
                fontSize: 14,
              }}
            >
              Tel: 054-272-8890
            </span>
          </div>
        </div>
      ) : (
        <>
          <HeaderBar isSearchPage={isSearchPage} nowPage={nowPage} />
          <div className="main-container">
            <main>{children}</main>
          </div>
          <AddConfirmModal />
          <BottomNavigationBar nowPage={nowPage} />
          <FooterBar />
        </>
      )}
    </>
  )
}

export default Layout
