import React, { useContext } from "react"
import { globalContext } from "../../../contexts/GlobalContext"
import {
  FOOTER_BUTTON_LIST,
  FOOTER_FIRST_ADDRESS,
  FOOTER_PHONE_NUMBER,
  FOOTER_SECOND_ADDRESS,
  FOOTER_TITLE,
} from "../../../values"
import FooterButton from "../../atom/FooterButton"
const styles = require("./style.module.scss")

const FooterBar = () => {
  const isPc = useContext(globalContext)?.isPc

  return (
    <footer className={styles["container"] + " v-box"}>
      <div className={styles["footerContainer"]}>
        <span className={styles["title"]}>{FOOTER_TITLE}</span>
        <span className={styles["subTitle"]}>{FOOTER_FIRST_ADDRESS}</span>
        <span className={styles["subTitle"]}>{FOOTER_SECOND_ADDRESS}</span>
        <span
          className={styles["subTitle"]}
          style={{
            paddingBottom: 30,
            borderBottom: "1px solid rgba(0,0,0,0.2)",
          }}
        >
          {FOOTER_PHONE_NUMBER}
        </span>

        <div className={styles["footerNavigation"] + " h-box"} style={{ marginTop: 20 }}>
          {FOOTER_BUTTON_LIST.map((item, index) => (
            <FooterButton {...item} key={item.name} />
          ))}
        </div>
      </div>
    </footer>
  )
}

export default FooterBar
