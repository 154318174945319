import { Link } from "gatsby"
import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import { globalContext } from "../../../contexts/GlobalContext"
import { HEADER_COLOR, HEADER_TITLE, PAGE } from "../../../values"
import HeaderButton from "../../atom/HeaderButton"
import SearchBar from "../../molecule/SearchBar"
import { navigate } from "gatsby"
import MovingAnnouncement from "../../molecule/MovingAnnouncement"
import { css } from "@emotion/react"
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
  Paper,
  SwipeableDrawer,
  Tabs,
} from "@material-ui/core"
import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined"
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined"
import AnnouncementOutlinedIcon from "@material-ui/icons/AnnouncementOutlined"
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn"
import SearchIcon from "@material-ui/icons/Search"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import { Tab } from "@material-ui/core"

const styles = require("./style.module.scss")

const useStyles = makeStyles({
  menuList: {
    fontSize: 15,
    fontWeight: 500,
  },
  eventMenu: {
    fontSize: 15,
  },
  cornerList: {
    fontSize: 14,
    fontWeight: 500,
  },
  listIcon: {
    minWidth: 45,
    color: "#444444",
  },
  listHeader: {
    lineHeight: "40px",
    color: "white",
    fontWeight: 500,
    backgroundColor: "#b53843" + "D9",
    marginBottom: 10,
  },
  tab: {
    fontSize: 13.5,
    fontWeight: 700,
    fontFamily: "나눔고딕",
    padding: "8px 10px",
    minHeight: "auto",
    color: "rgba(0,0,0,0.8)",
    opacity: 1,
  },
})

const HeaderBar = ({ isSearchPage, nowPage }: { isSearchPage: boolean; nowPage: PAGE }) => {
  const classes = useStyles()
  const [hoveredButtonIndex, setHoveredButtonIndex] = useState<number>(-1)
  const [isSearchMode, setIsSearchMode] = useState<boolean>(isSearchPage || false)
  const isFirstRender = useRef<boolean>(true)
  const scrollPos = useRef<number>(0)

  const [isScrollingUp, setIsScrollingUp] = useState<boolean>(false)
  const [isMenuOn, setIsMenuOn] = useState<boolean>(false)

  const searchInputRef = useRef(null)

  const context = useContext(globalContext)
  const numOfItemsInCart = context?.deliveryReducer.deliveryStates.delivery.items.length

  const root = css`
    border-bottom: 2px solid #d4d4d4;

    @media (max-width: 1024px) {
      border-bottom: none;
      position: sticky;
      top: 0;
      z-index: 10;
    }
  `

  const subHeader = css`
    display: none;

    @media (max-width: 1024px) {
      display: block;
      padding-bottom: 2px;
      background-color: rgb(243, 243, 243);
    }
  `

  const sortedList = useMemo(() => {
    context?.categoryHook.data?.sort((a, b) => {
      return a.name < b.name ? -1 : a.name > b.name ? 1 : 0
    })
    return context?.categoryHook.data
  }, [context?.categoryHook.data])

  useEffect(() => {
    console.log(isFirstRender.current)
    if (isSearchMode && !(isFirstRender.current && isSearchPage)) {
      searchInputRef.current.focus()
    }
    isFirstRender.current = false
  }, [isSearchMode])

  const MenuDrawer = () => (
    <SwipeableDrawer
      anchor="right"
      open={isMenuOn}
      onOpen={() => {
        setIsMenuOn(true)
      }}
      onClose={() => {
        setIsMenuOn(false)
      }}
    >
      <List
        style={{ width: 280, marginBottom: 15 }}
        subheader={
          <ListSubheader component="div" id="nested-list-subheader" className={classes.listHeader}>
            주메뉴
          </ListSubheader>
        }
      >
        <ListItem
          button
          key="event"
          disabled={!context?.eventHook.getIsEventActivated()}
          onClick={() => {
            navigate(PAGE.EVENT_PRODUCT)
          }}
        >
          <ListItemIcon className={classes.listIcon}>
            <MonetizationOnIcon
              style={{
                fontSize: 27,
                fill: "#b53843",
              }}
            />
          </ListItemIcon>
          <ListItemText
            primary="행사제품"
            classes={{
              primary: classes.eventMenu,
            }}
          />
        </ListItem>
        <ListItem
          button
          key="cart"
          onClick={() => {
            navigate(PAGE.SHOPPING_CART)
          }}
        >
          <ListItemIcon className={classes.listIcon}>
            <ShoppingCartOutlinedIcon style={{ fontSize: 24 }} />
          </ListItemIcon>
          <ListItemText
            primary="장바구니"
            classes={{
              primary: classes.menuList,
            }}
          />
        </ListItem>
        <ListItem
          button
          key="delivery"
          onClick={() => {
            navigate(PAGE.DELIVERY)
          }}
        >
          <ListItemIcon className={classes.listIcon}>
            <LocalShippingOutlinedIcon style={{ fontSize: 24 }} />
          </ListItemIcon>
          <ListItemText
            primary="배달현황"
            classes={{
              primary: classes.menuList,
            }}
          />
        </ListItem>
        <ListItem
          button
          key="announcement"
          onClick={() => {
            navigate(PAGE.ANNOUNCEMENT)
          }}
        >
          <ListItemIcon className={classes.listIcon}>
            <AnnouncementOutlinedIcon style={{ fontSize: 24 }} />
          </ListItemIcon>
          <ListItemText
            primary="공지사항"
            classes={{
              primary: classes.menuList,
            }}
          />
        </ListItem>
        {/* <Divider style={{marginTop: 20}}/> */}
      </List>

      <List
        subheader={
          <ListSubheader component="div" id="nested-list-subheader" className={classes.listHeader}>
            코너별 상품
          </ListSubheader>
        }
        style={{ maxWidth: 280 }}
      >
        {sortedList?.map(item => (
          <ListItem
            button
            key={item.name}
            onClick={() => {
              setIsMenuOn(false)
              context?.categoryProducts.setCategory(item)
              navigate(PAGE.CATEGORY_PRODUCT)
            }}
            style={{
              paddingTop: 8,
              paddingBottom: 8,
            }}
          >
            {/* <ListItemIcon className={classes.listIcon}>
                      <ShoppingCartOutlinedIcon />
                    </ListItemIcon> */}
            <ListItemText
              primary={item.name}
              classes={{
                primary: classes.cornerList,
              }}
            />
          </ListItem>
        ))}
      </List>
    </SwipeableDrawer>
  )

  return (
    <header style={{ backgroundColor: HEADER_COLOR }} css={root}>
      <div className={styles["container"]} style={{}}>
        <div className={styles["desktopContentContainer"]}>
          {MenuDrawer()}
          <Link to="/" className={styles["titleText"]}>
            {HEADER_TITLE}
          </Link>
          <div
            className="h-box"
            style={{
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <SearchBar searchInputRef={searchInputRef} />
            <div className={styles["desktopMenus"]}>
              <div
                className={"v-box " + styles["textButton"]}
                style={{ marginLeft: 10 }}
                onMouseEnter={() => setHoveredButtonIndex(0)}
                onMouseLeave={() => setHoveredButtonIndex(-1)}
                onClick={() => navigate(PAGE.ANNOUNCEMENT)}
              >
                <HeaderButton
                  src={
                    hoveredButtonIndex === 0
                      ? require("../../../images/announcement-accent.svg")
                      : require("../../../images/announcement.svg")
                  }
                />
                <span
                  className={styles["desktopMenuTitle"]}
                  style={{ color: hoveredButtonIndex === 0 ? "#b53843" : undefined }}
                >
                  공지
                </span>
              </div>
              <div
                className={"v-box " + styles["textButton"]}
                onMouseEnter={() => setHoveredButtonIndex(1)}
                onMouseLeave={() => setHoveredButtonIndex(-1)}
                onClick={() => navigate(PAGE.SHOPPING_CART)}
              >
                <HeaderButton
                  src={
                    hoveredButtonIndex === 1
                      ? require("../../../images/shopping-cart-accent.svg")
                      : require("../../../images/shopping-cart.svg")
                  }
                />
                <span
                  className={styles["desktopMenuTitle"]}
                  style={{ color: hoveredButtonIndex === 1 ? "#b53843" : undefined }}
                >
                  카트
                </span>
                {numOfItemsInCart !== undefined && numOfItemsInCart > 0 ? (
                  <div className={styles["cartItemCountIndicator"]}>{numOfItemsInCart}</div>
                ) : null}{" "}
              </div>
              <div
                className={"v-box " + styles["textButton"]}
                style={{ marginRight: 10 }}
                onMouseEnter={() => setHoveredButtonIndex(2)}
                onMouseLeave={() => setHoveredButtonIndex(-1)}
                onClick={() => navigate(PAGE.DELIVERY)}
              >
                <HeaderButton
                  src={
                    hoveredButtonIndex === 2
                      ? require("../../../images/delivery-accent.svg")
                      : require("../../../images/delivery.svg")
                  }
                  style={{ margin: 0 }}
                />
                <span
                  className={styles["desktopMenuTitle"]}
                  style={{ color: hoveredButtonIndex === 2 ? "#b53843" : undefined }}
                >
                  배달
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className={styles["mobileContentContainer"]}>
          <div
            className={styles["titleAndMenuContainer"]}
            style={{
              alignItems: "center",
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 3,
              paddingBottom: 3,
            }}
          >
            {!isSearchMode ? (
              <Link
                to="/"
                className={styles["titleText"]}
                style={{
                  fontSize: 17,
                  letterSpacing: "-0.6px",
                }}
              >
                {HEADER_TITLE}
              </Link>
            ) : (
              <>
                <button
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    border: "none",
                    background: "none",
                  }}
                  onClick={() => setIsSearchMode(false)}
                >
                  <ArrowBackIcon />
                </button>
                <SearchBar
                  style={{
                    marginLeft: isScrollingUp ? 10 : undefined,
                    marginBottom: isScrollingUp ? 5 : undefined,
                  }}
                  searchInputRef={searchInputRef}
                />
              </>
            )}
            <div style={{ display: "flex", flexDirection: "row" }}>
              {!isSearchMode && (
                <>
                  <button
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      border: "none",
                      background: "none",
                    }}
                    onClick={() => {
                      setIsSearchMode(true)
                    }}
                  >
                    <SearchIcon style={{ fontSize: 25 }} />
                  </button>
                  <button
                    className={styles["menuButton"]}
                    onClick={() => setIsMenuOn(!isMenuOn)}
                    style={{ marginLeft: 10 }}
                  >
                    <img
                      className={styles["menuIcon"]}
                      src={require("../../../images/menu-list.svg")}
                    />
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div css={subHeader}>
        <Tabs
          value={nowPage}
          style={{
            minHeight: "auto",
            border: "1px solid rgba(0,0,0,0.15)",
            background: "rgb(248,248,248)",
          }}
          onChange={(event, newValue) => {
            navigate(newValue)
          }}
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab
            label={"행사제품"}
            value={PAGE.EVENT_PRODUCT}
            className={classes.tab}
            disabled={!context?.eventHook.getIsEventActivated()}
          />
          <Tab label="코너별상품" value={PAGE.CATEGORY_PRODUCT} className={classes.tab} />
          <Tab label="공지사항" value={PAGE.ANNOUNCEMENT} className={classes.tab} />
          <Tab label="홈" value={PAGE.HOME} className={classes.tab} />
        </Tabs>
      </div>
    </header>
  )
}

export default HeaderBar
