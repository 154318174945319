import React, { useEffect, useState } from "react"
import { apiListProducts } from "../../../functions/api/product"
import { Product } from "../../../types"

const useSearchBar = (search?: string) => {
  const [searchText, setSearchText] = useState<string>(search || "")
  const [timer, setTimer] = useState<any>()
  const [searchedProductStrings, setSearchedProductStrings] = useState<string[][]>([])
  const [showSearchResult, setShowSearchResult] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onChangeText = (text: string) => {
    if (text != "") {
      setShowSearchResult(true)
    } else {
      setShowSearchResult(false)
    }

    setSearchText(text)
    window.localStorage.setItem("searchText", text)
  }

  useEffect(() => {
    const savedSearchText = window.localStorage.getItem("searchText")
    setSearchText(savedSearchText || "")
  }, [])

  useEffect(() => {
    if (searchText !== "" && showSearchResult) {
      if (timer) clearTimeout(timer)
      setIsLoading(true)

      setTimer(
        setTimeout(async () => {
          let searchedList = []
          let token

          for(let i=0;i<100;i++) {
            const response = await apiListProducts({
              name: {
                contains: searchText,
              },
            }, 120, token)
            if (response) {
              searchedList.push(...response.items)
              if (searchedList.length >= 5) {
                break
              }

              token = response?.nextToken
              if(token===null) {
                break
              }
            }
          }

          let result = []
          if (searchedList) {
            for (let i = 0; i < searchedList.length; i++) {
              const strings = searchedList[i].name.split(searchText)
              result.push(strings)
            }
            setSearchedProductStrings(result)
            setIsLoading(false)
          } else {
            setSearchedProductStrings([])
            setIsLoading(false)
          }
        }, 700)
      )
    }
  }, [searchText, showSearchResult])

  function isSearching() {
    return isLoading
  }

  return {
    searchText,
    setSearchText: onChangeText,
    searchedProductStrings,
    isSearching,
    showSearchResult,
    setShowSearchResult,
  }
}

export default useSearchBar
