import React, { useContext, useRef } from "react"
import { navigate } from "gatsby"
import { MarginStyle } from "../../../types"
import { PAGE, SEARCH_BAR_HINT } from "../../../values"
import useSearchBar from "./useSearchBar"
import { globalContext } from "../../../contexts/GlobalContext"
import SearchIcon from "@material-ui/icons/Search"

import useDetectOutsideClick from "../../../hooks/useDetectClickOutside"
const styles = require("./style.module.scss")

interface SearchBarProps {
  style?: MarginStyle
  searchInputRef: any
}

const SearchBar = ({ style, searchInputRef }: SearchBarProps) => {
  const {
    searchText,
    setSearchText,
    isSearching,
    searchedProductStrings,
    showSearchResult,
    setShowSearchResult,
  } = useSearchBar()
  const searchHook = useContext(globalContext)?.searchHook
  const searchRef = useRef(null)

  const handleKeyDown = (e: any) => {
    if (e.key == "Enter" && searchText !== "") {
      searchHook?.setSearch(new String(searchText))
      searchHook?.setShouldScroll(true)
      navigate(PAGE.SEARCH_RESULT)

      //navigate(PAGE.SEARCH_RESULT, { state: { search: new String(searchText) } })
      setShowSearchResult(false)
    }
  }

  function handleClickSearchButton() {
    if (searchText !== "") {
      searchHook?.setSearch(new String(searchText))
      searchHook?.setShouldScroll(true)
      navigate(PAGE.SEARCH_RESULT)

      //navigate(PAGE.SEARCH_RESULT, { state: { search: new String(searchText) } })
      setShowSearchResult(false)
      searchInputRef.current.blur()
    }
  }

  useDetectOutsideClick(searchRef, () => {
    if (showSearchResult) {
      setShowSearchResult(false)
    }
  })

  return (
    <div ref={searchRef} className={styles["container"]}>
      <div className={styles["searchInputContainer"]} style={style}>
        <input
          value={searchText}
          onKeyDown={handleKeyDown}
          onFocus={() => setShowSearchResult(true)}
          onChange={event => {
            setSearchText(event.target.value)
          }}
          placeholder={"검색어를 입력해주세요"}
          className={styles["searchInput"]}
          style={{
            color: searchText !== "" ? "black" : undefined,
          }}
          ref={searchInputRef}
        />
        <button
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            border: "none",
            background: "none",
          }}
          onClick={handleClickSearchButton}
        >
          <SearchIcon style={{ fontSize: 25 }} />
        </button>
      </div>
      {showSearchResult && (
        <div className={styles["searchResultContainer"]}>
          {isSearching() ? (
            <span className={styles["searchLoadingText"]}>탐색중</span>
          ) : searchedProductStrings!.length !== 0 ? (
            searchedProductStrings!.slice(0, 5).map((item, idx) => (
              <div
                className="h-box"
                onClick={() => {
                  searchHook?.setSearch(new String(item.name))
                  searchHook?.setShouldScroll(true)

                  setSearchText(document.getElementById(`searchResult${idx}`)?.textContent || "")
                  navigate(PAGE.SEARCH_RESULT)
                  setShowSearchResult(false)
                }}
              >
                <span id={"searchResult" + idx} className={styles["searchResultText"]}>
                  {item.map((i, idx) => (
                    <span className={styles["searchResultHighlight"]}>
                      {i == "" && idx != item.length - 1 ? (
                        <span>{searchText}</span>
                      ) : idx != item.length - 1 ? (
                        <span className={styles["searchResultDim"]}>
                          {i}
                          <span className={styles["searchResultHighlight"]}>{searchText}</span>
                        </span>
                      ) : (
                        <span className={styles["searchResultDim"]}>{i}</span>
                      )}
                    </span>
                  ))}
                </span>
              </div>
            ))
          ) : (
            <span className={styles["searchResultNone"]}>검색 결과 없음</span>
          )}
        </div>
      )}
    </div>
  )
}

export default SearchBar
