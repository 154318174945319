import React from "react"
import BottomNavigation from "@material-ui/core/BottomNavigation"
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction"
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart"
import LocalShippingIcon from "@material-ui/icons/LocalShipping"
import CallIcon from "@material-ui/icons/Call"
import Badge from "@material-ui/core/Badge"
import { PAGE } from "../../../values"
import { navigate } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import { useContext } from "react"
import { globalContext } from "../../../contexts/GlobalContext"
import { css } from "@emotion/react"

interface BottomNavigationBarProps {
  nowPage: PAGE
}

const container = css`
  display: none;

  @media (max-width: 1024px) {
    display: flex;
  }
`

const useStyles = makeStyles(theme => ({
  badge: {
    top: 13,
    right: -3,
    border: "2px solid white",
    padding: "0 3px",
  },
  deliveryBadge: {
    top: 13,
    right: -4,
    border: "2px solid white",
    padding: 4,
    borderRadius: 10,
  },
}))

const BottomNavigationBar = ({ nowPage }: BottomNavigationBarProps) => {
  const classes = useStyles()
  const context = useContext(globalContext)

  return (
    <div css={container}>
      <BottomNavigation
        value={nowPage}
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          paddingTop: 6,
          zIndex: 5,
          boxShadow: "0px -3px 3px rgba(0,0,0,0.1)",
        }}
        showLabels
      >
        {/* <BottomNavigationAction
          label="매장전화"
          value=""
          icon={<CallIcon />}
          onClick={() => {
            window.open("tel:0542728890")
          }}
        /> */}
        <BottomNavigationAction
          label="주문현황"
          value={PAGE.DELIVERY}
          icon={
            <Badge
              variant="dot"
              color="primary"
              classes={{ badge: classes.deliveryBadge }}
              invisible={!context?.deliveryReducer.hasSentDelivery}
            >
              <LocalShippingIcon />
            </Badge>
          }
          onClick={() => {
            context?.deliveryReducer.setHasSentDelivery(false)
            navigate(PAGE.DELIVERY)
          }}
        />
        <BottomNavigationAction
          value={PAGE.DELIVERY}
        />
        <BottomNavigationAction
          label="장바구니"
          value={PAGE.SHOPPING_CART}
          icon={
            <Badge
              badgeContent={context?.deliveryReducer.deliveryStates.delivery.items.length}
              color="primary"
              classes={{ badge: classes.badge }}
            >
              <ShoppingCartIcon />
            </Badge>
          }
          onClick={() => navigate(PAGE.SHOPPING_CART)}
        />
      </BottomNavigation>
    </div>
  )
}

export default BottomNavigationBar
