import React, { useContext } from "react"
import { css } from "@emotion/react"
import Modal from "react-modal"
import { globalContext } from "../../../contexts/GlobalContext"
import moment from "moment"
import { numberWithCommas } from "../../../functions"
import ProductItem from "../../molecule/ProductItem"
import Snackbar from "@material-ui/core/Snackbar"
import SnackbarContent from "@material-ui/core/SnackbarContent"
import { makeStyles } from "@material-ui/core"
const styles = require("./style.module.scss")

interface AddConfirmModalProps {}

const snackbarStyle = css`
  left: 50%;
  width: auto;
  /* transform: translateX(50%); */
  @media (max-width: 1024px) {
    bottom: 60px;
    width: 100vw;
    border-radius: 0px;
    left: 50%;
    transform: translateX(-50%);
  }
`
const snackbarContentStyle = `
  
  height: auto;
  line-height : 21px ;
  font-size: 15px;
  white-space: nowrap;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.5);

  @media (max-width: 1024px){
    white-space: pre-line;
  }

`
const useStyles = makeStyles({
  snackbarStyle: {
    left: "50%",
    width: "auto",
    ["@media (max-width: 1024px)"]: {
      // eslint-disable-line no-useless-computed-key
      bottom: 60,
      width: "100%",
      borderRadius: 0,
      left: "50%",
      transform: "translateX(-50%)",
    },
  },
  snackbarContentStyle: {
    height: "auto",
    lineHeight: "21px",
    fontSize: 15,
    whiteSpace: "nowrap",
    backdropFilter: "blur(10px)",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    ["@media (max-width: 1024px)"]: {
      whiteSpace: "pre-line",
    },
  },
})

const AddConfirmModal = ({}: AddConfirmModalProps) => {
  const classes = useStyles()

  const data = useContext(globalContext)?.addConfirmModalHook
  Modal.setAppElement("body")

  return (
    <Snackbar
      className={classes.snackbarStyle}
      open={data?.isModalOn}
      onClick={() => {
        data?.continueShopping()
      }}
      autoHideDuration={1500}
      onClose={() => {
        data?.continueShopping()
      }}
    >
      <SnackbarContent
        className={classes.snackbarContentStyle}
        message={
          data?.addedCartItem?.product.name +
          "\n" +
          data?.addedCartItem?.count +
          "개가 장바구니에 추가되었습니다"
        }
      />
    </Snackbar>
  )
}

export default AddConfirmModal
