import React from "react"
import { navigate } from 'gatsby';
import { PAGE } from "../../../values";

export interface FooterButtonProps {
  name: string;
  destination: PAGE;
  style?: React.CSSProperties;
}

const FooterButton = ({name, destination}: FooterButtonProps) => {
  function handleOnClick() {
    navigate(destination)
  }

  return (
    <span onClick={handleOnClick}>
      {name}
    </span>
  )
}

export default FooterButton